/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Desi Karunia Sari
==============================================================================================*/
@import "sass-lib.scss";
@media (min-width: 0px) and (max-width:1680px){
    header{
        &.active{
            .wrapper-large{
                .logo{
                    margin-left: 35px;
                }
                ul{
                    &.menu{
                        margin-right: 60px;
                    }
                }
            }
        }
    }
    .landing{
    	.box{
    		.index-img{
    			height: 768px;
    			.img-landing{ height:100vh; }
    			.desc-landing{
    				padding: 35px 25px;width: 30.4vw;
    				.title{ font-size: 33px; }
    			}
    		}
    	}
    	.highlight-text{
    		h1{ font-size: 33px; }
    	}
    }
    .latest-news{
    	.box-latest-news{
    		.box-news-title{
                padding: 80px 0px 80px 30px;
                h2{
                    font-size: 40px;
                }
            }
            .box-news{
                width: 290px; margin:80px 0;
                .box-title{
                    font-size: 23px;
                }
            }
    	}
    }
    .homepage{
        .main-slider{
            .content{
                h1{
                    font-size: 48px;
                }
            }
            .slick-dots{
                li{
                    button{
                        left: 1530px;
                    }
                }
            }
        }
    }
    .text-dirext{
        font-size: 16px;
    }
    .about-us{
        .content{
            .img-content{
                margin-right: 0px;
                img{
                    width: 500px;
                }
            }
            .desc-content{
                margin-left: 280px;
                .highlight-text{
                    font-size: 33px;
                }
                .logo-content{
                    margin-top: -50px;
                }
            }
        }
    }
    .mid-content{
        .middle{
            .box-content{
                width: 33.33%;
                h3{
                    font-size: 33px;
                }
            }
        }
    }
    .pagingInfo{
        right: 290px;
    }
    .video-project{
        .video-slider{
            .item{
                .cover-thumb{
                    img{
                        margin: auto;width: 80px;
                    }
                }
            }
        }
    }
    .slick-slide img{
        max-width: 100%;
    }
    footer{
        .menu-footer{
            margin-top: -60px;
        }
    }
}

@media (min-width: 0px) and (max-width:1440px){
    .wrapper-large{width: 1300px;margin: 0 auto;}
    header{
        .wrapper-large{
            padding: 30px 80px 15px 0px;
            .menu{
                margin-right: -70px;
            }
            .logo{
                margin-top: 0;
                img{
                    width: 250px;
                }
            } 
            ul{
                &.menu{
                    li{
                        a{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
        &.active{
            .wrapper-large{
                .logo{
                    margin-left: 15px;
                }
                ul{
                    &.menu{
                        margin-right: 15px;margin-top:-10px;
                        li{
                            a{
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
    .landing{
        .box{
            .index-img{
                height: 100vh;
                .img-landing{
                    .bg{
                        height: 100vh;
                    }
                }
            }
        }
    }   
    .box-video .slick-arrow.nav-left{ right: 300px; }
    .box-video .slick-arrow.nav-right{ right: 230px; }
    .homepage{
        .main-slider{
            .content{
                padding: 40px 30px;
            }
            h1{
                font-size: 40px;
            }
            .list{
                height: auto;
            }
            .slick-dots{
                li{
                    button{
                        left: 1200px; top: -65px;
                    }
                }
            }
        }
    }
    .wrapper-large.about-us{ position: relative; top: -9px; }
    .about-us{
        padding: 150px 30px;
        .content{
            .img-content{
                width: 30%;margin-left: -27%;
                img{
                    width: 457px;
                }
            }
            .desc-content{
                margin-left: 130px;
                .highlight-text{
                    font-size: 33px;line-height: 40px;
                }
            }
        }
    } 
    .video-project{
        .video-slider{
            .item{
                .desc-project{
                    left: 4%;width: 1000px;
                    .info-text{
                        h2{
                            font-size: 40px;
                        }
                    }
                    .description{
                        p{
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    .mid-content{
        .middle{
            .info{
                h2{
                    font-size: 33px;line-height: 40px; width: 90%;
                }
            }
            .highlight-text{
                font-size: 33px;line-height: 40px;
            }
            .content-left,.content-right{
                .title{
                    font-size: 33px;
                }
            }
            .content-left{
                .image-cover{
                    max-height: 300px; 
                    img{
                        /*width: 90%;*/ margin-bottom: 50px;
                    }
                }
            }
            .list-profile{
                .profile{
                    width: 285px;
                }
            }
        }
        &.visionmission{
            padding: 60px 150px;
        }
    }
    .menu-id{
        ul{
            li{
                a{
                    font-size: 13px;
                }
            }
        }
    }
    .csr-slider{
        margin-bottom: 130px;
        .list{
            img{
                width: 100%; border-radius: 5px;
            }
        }
    }
    .pagingInfo{
        right: 135px;
    }
    .homepage-project{
        p{font-weight: lighter;color: #999;}
        a{text-align: right;}
        .box-40{width: 40% !important}
        .box-20{width: 20% !important}
        .middle {
            .box-content{
                width: 100%;
                h3{  }
                p{ padding: 0; }
                .text-direct{ margin: 0; }
            } 
        }
    }
    .mid-content .middle .news{ width: 21vw; }
    .modal .modal-content{ top: 25%; left: 50%; }
    .modal video{ height: 500px; }
    #ytplayer{ height: 477px; width: 100%; position: relative; left: 0; }
    .box-video.details .video-project .vid_web iframe{ width: 100%; height: 659px; }
    footer{
        .info{
            margin-left: 15px;
        }
        .menu-footer{
            margin-right: 25px;
            ul{
                li
                {
                    a{
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

@media (min-width: 0px) and (max-width:1360px){
    header{
        .wrapper-large{
            padding: 30px 80px 15px 20px;
            .menu{
                /*margin-right: 0px;*/
            }
        }
    }
}

